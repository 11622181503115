import React from 'react'
import Logo from '../../AppAssets/Logo.png';
const Header = () => {
  return (
    <div className='flex flex-col space-y-4 items-center'>
         <div className='flex flex-col items-center mt-4 space-y-4 '>
                <img src={Logo} className="w-[170px] h-[50px]" alt='Print Center' />
                <span className='text-xl font-light '>Your FREE Drag and Drop Newsletter & Calendar Creation</span>
     </div>

      <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4">
      <div className=' space-y-4 rounded-lg p-2 my-2 w-[281px]  text-center' style={{backgroundColor:"#ffff",color:"#404040",border:"solid 3px #E7E7E7"}}>
        <span className='text-lg text- font-light'>
        Easily Create pages, slides and calendars to print, save as images or PDF files. Share your layouts and content with your peers.
        </span>
        </div>
        <div className=' space-y-4 rounded-lg p-2 my-2 text-center w-[281px]' style={{backgroundColor:"#ffff",color:"#404040",border:"solid 3px #E7E7E7"}}>
        <span className='text-xl block font-light'>Why Prynter?</span>
        <span className='text-lg font-light'>Because we believe this simple task should be free and shareable to save everyone time. </span>
        </div>
        <div className=' space-y-4 rounded-lg p-2 my-2 w-[281px] text-center' style={{backgroundColor:"#ffff",color:"#404040",border:"solid 3px #E7E7E7"}}>
        <span className='text-lg font-light' >
        Sign up for a free account and start using prntyr right now. Lots of templates and ideas inside.
        </span>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Header