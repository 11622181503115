import React from 'react';
import PasswordReset from '../AppComponents/PasswordReset';

import Header from '../AppComponents/Header/Header';
import Footer from '../AppComponents/Footer/Footer';


const ResetPassword = () => {
  return (
    <div className='flex flex-col space-y-4 items-center'> 
     <Header/>
        <div className='w-[60%]'>
        <PasswordReset/>
        </div>
       <Footer/>
     </div>
  )
}

export default ResetPassword