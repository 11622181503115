import moment from "moment/moment";
import { useContext } from "react";
import { createContext,useState } from "react";

import AuthContext from "./AuthContext";
import { API,Auth} from "aws-amplify";
import awsConfig from "../aws-config";
import { CategoryItems } from "../Apps/CreatorsApp/Utils/CreateShareUtils";
API.configure(awsConfig);

const CreatorsSharedContext=createContext();
export default CreatorsSharedContext;


export const CreatorsSharedContextProvider = ({children}) => {
    
    const {UserName,UserId}=useContext(AuthContext);    
    const [SharedTemplatesData,setSharedTemplatesData]=useState([]);
    const [SharedCreatorsContentLoader,setSharedCreatorsContentLoader]=useState(false);
    const [UpdateSharedTemplateUseAndRatingLoader,setUpdateSharedTemplateUseAndRatingLoader]=useState(false);
    const [IsSpeak2Admin,setIsSpeak2Admin]=useState(false);
    const NumberOfPagesToDisplayOnPaginationForSharedTemplates=5;
    const [PageSizeForSharedTemplates,setPageSizeForSharedTemplates]=useState(15);
    const [CurrentPageForSharedTemplates,setCurrentPageForSharedTemplates]=useState(1);
    const [TotalNumberOfSharedTemplates,setTotalNumberOfSharedTemplates]=useState(15);
    const [IsLoadedAllSharedTempaltes,setIsLoadedAllSharedTempaltes]=useState(false);
    const [CategoriesTemplateCont,setCategoriesTemplateCont]=useState(null);
    const [FileTypeTemplates,setFileTypeTemplates]=useState([]);
    // Get Community Content 
    const getCreatorsSharedTemplates=async(currentpage=1)=>{
        setSharedCreatorsContentLoader(true)
        try {
        
          const response = await API.get('Creators-templates', '/creators-shared-templates', {
            response: true,
            queryStringParameters: {
              page: currentpage,
              limit : IsLoadedAllSharedTempaltes===true?15:PageSizeForSharedTemplates,
              // add more query params as needed
            }
          });
          if(IsLoadedAllSharedTempaltes===true)
          {
            setIsLoadedAllSharedTempaltes(false);
            setPageSizeForSharedTemplates(15);
          }
            // console.log("Data for images is ",JSON.stringify(response.data));
            let filteredCommunityContent=response.data.data;
            // console.log("categories template count is ",JSON.stringify(response.data.CategoriesTemplateCount));
            setCategoriesTemplateCont(response.data.CategoriesTemplateCount);
        setSharedTemplatesData(filteredCommunityContent);
        setTotalNumberOfSharedTemplates(response.data.totalTemplates);
          setSharedCreatorsContentLoader(false);
        } catch (error) {
            setSharedCreatorsContentLoader(false);
          console.log("something went wrong ",error);
        }
        setSharedCreatorsContentLoader(false);
      }
  
    //  Get Creators shared file type templates 
    const getCreatorsSharedFileTypeTemplates=async()=>{
      setSharedCreatorsContentLoader(true)
      try {
      
        const response = await API.get('Creators-templates', '/creators-shared-templates', {
          response: true,
          queryStringParameters: {
            getFileTypeSharedTemplates: 'true',
            // add more query params as needed
          }
        });
          // console.log("Data for File Type is ",JSON.stringify(response.data));
          let filteredCommunityContent=response.data.data;
          setFileTypeTemplates(filteredCommunityContent);
        setSharedCreatorsContentLoader(false);
      } catch (error) {
          setSharedCreatorsContentLoader(false);
        console.log("something went wrong ",error);
        setFileTypeTemplates([]);
      }
      setSharedCreatorsContentLoader(false);
    }
      // get all Creators shared Templates

      const getAllCreatorsSharedTemplates=async()=>{
        setSharedCreatorsContentLoader(true)
        let currentpage=1;
        let PageSize=PageSizeForSharedTemplates;
        let responsesize=PageSizeForSharedTemplates;
        let SharedTemplates=[];
        while(PageSize===responsesize)
        {
          if(SharedCreatorsContentLoader===false)
          {
            setSharedCreatorsContentLoader(true)
          }
        try {
          const response = await API.get('Creators-templates', '/creators-shared-templates', {
            response: true,
            queryStringParameters: {
              page: currentpage,
              limit : PageSizeForSharedTemplates,
              // add more query params as needed
            }
          });
            // console.log("Data for images is ",JSON.stringify(response.data));
            let filteredCommunityContent=response.data.data;
            responsesize=filteredCommunityContent.length;
            setTotalNumberOfSharedTemplates(response.data.totalTemplates);
            setCategoriesTemplateCont(response.data.CategoriesTemplateCount);
            SharedTemplates=SharedTemplates.concat(filteredCommunityContent);
          
        } catch (error) {
          console.log("something went wrong ",error);
          break;
        }
        currentpage++;
      }

      setSharedTemplatesData(SharedTemplates);
      setPageSizeForSharedTemplates(SharedTemplates.length);
      setTotalNumberOfSharedTemplates(SharedTemplates.length);
      setCurrentPageForSharedTemplates(1);
      setIsLoadedAllSharedTempaltes(true);
      setSharedCreatorsContentLoader(false);
      }

      // Group Shared Tempaltes content by Category 
      const groupSharedTemplatesContentByCategory=(sharedtemplatesdata)=>{
        let formattedData = CategoryItems.reduce((acc, categoryItem) => {
          let categoryData = sharedtemplatesdata.filter(item => item.Category === categoryItem.CategoryName);
      
          if (categoryData.length > 0) {
              acc.push({
                  Category: categoryItem.CategoryName,
                  data: categoryData
              });
          }
      
          return acc;
      }, []);
      return formattedData;
      };

    const handleShareCreatorTemplate=async(templatename,templatetags,templateimage,templatedata,category,targetaudience,description)=>{
        // console.log("tempalte name is"+templatename+" and template tags are"+templatetags+"tempalte Type is"+templatetype);
        // console.log("\n Tempalte iamge is"+templateimage);
        // console.log("\n Template data is "+templatedata);
        const formattedDate = moment().format('YYYY-MM-DD');
        // console.log("\n User Name "+UserName);

        let sharedTemplate={
            TemplateId:SharedTemplatesData.length+1,
            TemplateName:templatename,
            CreatedBy:UserName,
            CreatedDate:formattedDate,
            TagsList:templatetags,
            TemplateImage:templateimage,
            TemplateData:templatedata,
            TemplateRating:null,
            RatedUsersIDs:[],
            TemplateUsedNumber:0,
            UserId:UserId,
            Category:category,
            TargetAudience:targetaudience,
            Description:description
        };
        let tempaltedata=await handleStoreSharedTemplate(sharedTemplate);
        let updateddata=sharedTemplate;
        if(tempaltedata!==false)
        {
            updateddata={...sharedTemplate,TemplateId:tempaltedata.TemplateId,TemplateImage:tempaltedata.TemplateImage}
        }
        
        let communitycontent=SharedTemplatesData;
        communitycontent.push(updateddata);
        setSharedTemplatesData(communitycontent);
        setSharedCreatorsContentLoader(false);
        return true;
    }

/*
    # handle Store Template in Db
*/
    const handleStoreSharedTemplate=async(Tempaltedata)=>{
        setSharedCreatorsContentLoader(true);
        let data=[];
        data.push(Tempaltedata);
        let RequestBody={
          Data:data
        };
      
        try {
          const response = await API.post('Creators-templates', '/creators-shared-templates', {
            body:RequestBody,
            response: true,
          });
      //  console.log("operation Successfull"+response.data);

          return response.data.data;
          //  setLocalStore(response.data.data);
        } catch (error) {
          console.log(error);
          // setLocalStore([]);
           return false;
    }
}
/* 
  handle Store File Type shared  Templates 
*/
const handleStoreFileTypeSharedTemplate=async(Tempaltedata,FileDetails,FileObject)=>{
  setSharedCreatorsContentLoader(true);
  let RequestBody={
    Data:Tempaltedata,
    FileDetails:FileDetails,
    ShouldGetPreSignedUrl:true,
  };

  try {
    const response = await API.post('Creators-templates', '/creators-shared-templates', {
      body:RequestBody,
      response: true,
    });
//  console.log("operation Successfull",JSON.stringify(response.data));
 let result =await uploadImageToS3(response.data.PreSignedUrl,FileObject,FileDetails.ContentType);
 console.log("result of uploading is",result);
 setSharedCreatorsContentLoader(false);
 if(result===true)
 {
  return response.data;
 }
 return null;
 
  
  } catch (error) {
    console.log(error);
     return null;
}
}
/*
  # Upload File To S3 
*/
const uploadImageToS3=async(signedUrl,file,contentType)=>{

  setSharedCreatorsContentLoader(true);
  try {
      const response = await fetch(signedUrl, {
          method: 'PUT',
          headers: {
              'Content-Type': contentType,
              'Content-Disposition':'attachment' // Ensure this matches the Content-Type in the signed URL
          },
          body: file // Directly use the File object from the input
      });

      if (response.ok) {
          console.log('Upload successful');
          setSharedCreatorsContentLoader(false);
          return true;
      } else {
          console.error('Upload failed');
          setSharedCreatorsContentLoader(false);
          return false;
      }
  } catch (error) {
      console.error('Error uploading the file:', error);
  }
}
/*
# handle Update Tempalte USed  and Rating 
*/
const handleUpdateCreatorSharedTemplateUsedAndRating=async(Tempaltedata)=>{
    setUpdateSharedTemplateUseAndRatingLoader(true);
    let data=[];
    data.push(Tempaltedata);
    let RequestBody={
      Data:data,
      TemplateId:Tempaltedata.TemplateId,
    };
    try {
      const response = await API.put('Creators-templates', '/creators-shared-templates', {
        body:RequestBody,
        response: true,
      });
      setUpdateSharedTemplateUseAndRatingLoader(false);
    return true;
      //  setLocalStore(response.data.data);
    } catch (error) {
      console.log(error);
      // setLocalStore([]);
      setUpdateSharedTemplateUseAndRatingLoader(false);
       return false;
}
}

/*
  # handle Update File Type Template 
*/
const handleUpdateFileTypeTemplate=async(Tempaltedata,FileDetails,FileObject)=>{
  setSharedCreatorsContentLoader(true);
  let RequestBody={
    Data:Tempaltedata,
    TemplateId:Tempaltedata.TemplateId,
    FileDetails:FileDetails,
    ShouldGetPreSignedUrl:true,
  };
  try {
    const response = await API.put('Creators-templates', '/creators-shared-templates', {
      body:RequestBody,
      response: true,
    });
    // console.log("Response for update is ",JSON.stringify(response.data));
    setSharedCreatorsContentLoader(false);
    let result =await uploadImageToS3(response.data.PreSignedUrl,FileObject,FileDetails.ContentType);
 console.log("result of uploading is",result);
 setSharedCreatorsContentLoader(false);
 if(result===true)
 {
  return response.data;
 }
 return null;
  
  } catch (error) {
    console.log(error);
    // setLocalStore([]);
    setSharedCreatorsContentLoader(false);
     return false;
}
}

// handle Detlete Community Content Tempalte
const handleDeleteCreatorsSharedTemplateBackend=async(Tempaltedata)=>{

  let RequestBody={
    TemplateId:Tempaltedata.TemplateId
  };

  setUpdateSharedTemplateUseAndRatingLoader(true);
  try {
    const response = await API.del('Creators-templates', '/creators-shared-templates', {
      body:RequestBody,
      response: true,
    });
    setUpdateSharedTemplateUseAndRatingLoader(false);
    console.log("operation Successfull"+response.data);
    return true;
    //  setLocalStore(response.data.data);
    
  } catch (error) {
    console.log(error);
    // setLocalStore([]);
    setUpdateSharedTemplateUseAndRatingLoader(false);
     return false;
  }
 }

     // get User Role 
     const getUserRole=async()=>{
      setSharedCreatorsContentLoader(true);
      const user=await Auth.currentAuthenticatedUser();
      const RequestBody={
       username: user.username,
      }
       try {
         let response=await API.post('lambda', '/users/profile/get', {
          body:RequestBody,
          response: true,
        });
        if(response.data.Success===true&&response.data.Content.Roles[0].Name==="Facility Admin" &&response.data.Content.Roles[0].IsActive===1)
        { 
          setIsSpeak2Admin(true);
          setSharedCreatorsContentLoader(false);
          return true;
        }
        setIsSpeak2Admin(false);
        setSharedCreatorsContentLoader(false);
        return false;
      } catch (error) {
        console.log(error);
        setIsSpeak2Admin(false);
        setSharedCreatorsContentLoader(false);
        return false;
      }
     };

//  handle get File object from signed url 
const  getFileObjectFromSignedUrl=async(signedUrl, filename)=>{
  setSharedCreatorsContentLoader(true);
  try {
      // Fetch the file content from the signed URL
      const response = await fetch(signedUrl);
      if (!response.ok) {
        console.log("Something went wrong while fethcing file")
        return null;
      }
      
      
      // Convert the response into a Blob
      const blob = await response.blob();
      
      // Create a file object from the Blob
      const file = new File([blob], filename, { type: blob.type });
      setSharedCreatorsContentLoader(false);
      return file;
  } catch (error) {
      console.error('Failed to fetch the file:', error);
      setSharedCreatorsContentLoader(false);
      return null;
  }
}
// convert file to base 64
function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
  });
}

    let ContextData={
        SharedTemplatesData:SharedTemplatesData,
        SharedCreatorsContentLoader:SharedCreatorsContentLoader,
        UpdateSharedTemplateUseAndRatingLoader:UpdateSharedTemplateUseAndRatingLoader,
        IsSpeak2Admin:IsSpeak2Admin,
        NumberOfPagesToDisplayOnPaginationForSharedTemplates:NumberOfPagesToDisplayOnPaginationForSharedTemplates,
        PageSizeForSharedTemplates:PageSizeForSharedTemplates,
        TotalNumberOfSharedTemplates:TotalNumberOfSharedTemplates,
        CurrentPageForSharedTemplates:CurrentPageForSharedTemplates,
        IsLoadedAllSharedTempaltes:IsLoadedAllSharedTempaltes,
        CategoriesTemplateCont:CategoriesTemplateCont,
        FileTypeTemplates:FileTypeTemplates,
        getFileObjectFromSignedUrl:getFileObjectFromSignedUrl,
        convertFileToBase64:convertFileToBase64,
        handleUpdateFileTypeTemplate:handleUpdateFileTypeTemplate,
        setFileTypeTemplates:setFileTypeTemplates,
        handleStoreFileTypeSharedTemplate:handleStoreFileTypeSharedTemplate,
        getCreatorsSharedFileTypeTemplates:getCreatorsSharedFileTypeTemplates,
        setCurrentPageForSharedTemplates:setCurrentPageForSharedTemplates,
        groupSharedTemplatesContentByCategory:groupSharedTemplatesContentByCategory,
        getUserRole:getUserRole,
        setUpdateSharedTemplateUseAndRatingLoader:setUpdateSharedTemplateUseAndRatingLoader,
        setSharedTemplatesData:setSharedTemplatesData,
        handleShareCreatorTemplate:handleShareCreatorTemplate,
        handleUpdateCreatorSharedTemplateUsedAndRating:handleUpdateCreatorSharedTemplateUsedAndRating,
        handleDeleteCreatorsSharedTemplateBackend:handleDeleteCreatorsSharedTemplateBackend,
        getCreatorsSharedTemplates:getCreatorsSharedTemplates,
        getAllCreatorsSharedTemplates:getAllCreatorsSharedTemplates,
        
    }
  return (
    <CreatorsSharedContext.Provider value={ContextData}>
    {children}
   </CreatorsSharedContext.Provider>
  )
  }
   