import React from 'react'
import Forgotpassword from '../AppComponents/Forgotpassword';
import Header from '../AppComponents/Header/Header';
import Footer from '../AppComponents/Footer/Footer';

const ForgotPassword = () => {
  return (
    <div className='flex flex-col space-y-4 items-center'> 
     <Header/>
        <div className='w-[60%]'>
        <Forgotpassword/>
        </div>
       <Footer/>
     </div>
  )
}

export default ForgotPassword