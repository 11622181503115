import React from 'react'
import EmailConfirmation from '../AppComponents/EmailConfirmation';
import Header from '../AppComponents/Header/Header';
import Footer from '../AppComponents/Footer/Footer';

const ConfirmEmail = () => {
  return (
    <div className='flex flex-col space-y-4 items-center'> 
      <Header/>

       <div className='w-[60%]'>
       <EmailConfirmation/>
       </div>

       <Footer/>

    </div>
  )
}

export default ConfirmEmail