import moment from "moment/moment";
import { useContext, useEffect } from "react";
import { createContext,useState } from "react";
import AuthContext from "./AuthContext";
import { API } from "aws-amplify";
import awsConfig from "../aws-config";
API.configure(awsConfig);

const ContentContext=createContext();
export default ContentContext;


export const ContentContextProvider = ({children}) => {
    
    const {UserName,UserId}=useContext(AuthContext);
    const [ConentData,setConentData]=useState([]);
    const [CreaterContentLoader,setCreaterContentLoader]=useState(false);
    const [UpdateContentUseAndRatingLoader,setUpdateContentUseAndRatingLoader]=useState(false);
    const [ContentTempalteFileLink,setContentTempalteFileLink]=useState(false);
    const [SecretKey, setSecretKey] = useState('');
    const [ConnectChatGPtLoader,setConnectChatGPtLoader]=useState(false);
    const [IsChatGptConnected,setIsChatGptConnected]=useState(null)
    const NumberOfPagesToDisplayOnPaginationOnContent=5;
    const [PageSizeOnContent,setPageSizeOnContent]=useState(15);
    const [CurrentPageOfContent,setCurrentPageOfContent]=useState(1);
    const [TotalNumberOfContentTemplates,setTotalNumberOfContentTemplates]=useState(15);
    const [IsLoadedAllContents,setIsLoadedAllContents]=useState(false);
    useEffect(()=>{
        handleGetChatGPtConnectionStatus();
        getSignedUrlForContentTemplateFile();
    },[])


  

    const handleSecretKeyChange = (e) => {
      setSecretKey(e.target.value);
    };

    const handleGetChatGPtConnectionStatus=async()=>{
      try {
        const response=await API.get('Connect-ChatGpt', '/connect-chatgpt', {
         response: true,
       });
       setConnectChatGPtLoader(false);
       setSecretKey(response.data.data.secretkey);
       setIsChatGptConnected(true);
     } catch (error) {
       console.log(error);
       setConnectChatGPtLoader(false);
       setIsChatGptConnected(false);
       setSecretKey("");
     }
    };


    
    // handle Added ChatGpt secret Key handleConnectChatGpt
    const handleConnectChatGpt=async()=>{
      if(SecretKey==="")
      {
        return;
      }
      setConnectChatGPtLoader(true);
      try {
        const response=await API.post('Connect-ChatGpt', '/connect-chatgpt', {
         body:{SecretKey:SecretKey},
         response: true,
       });
       setConnectChatGPtLoader(false);
       setIsChatGptConnected(true);
       
     } catch (error) {
       console.log(error);
       setConnectChatGPtLoader(false);
       setIsChatGptConnected(false);
     }
    }
 
    const handleDiconnectChatGpt = async() => {
      setConnectChatGPtLoader(true);
      try {
        const response=await API.del('Connect-ChatGpt', '/connect-chatgpt', {
         body:{},
         response: true,
       });
       setConnectChatGPtLoader(false);
       setIsChatGptConnected(false);
       setSecretKey("");
     } catch (error) {
       console.log(error);
       setConnectChatGPtLoader(false);
       setIsChatGptConnected(true);
     }
    };

    // Get Community Content 
    const getCreatersContent=async(currentpage=1)=>{
        setCreaterContentLoader(true)
        try {
          const response = await API.get('Creaters-Content-Templates', '/creaters-content', {
            response: true,
            queryStringParameters: {
              page: currentpage,
              limit : IsLoadedAllContents===true?15:PageSizeOnContent,
            }
          });

          if(IsLoadedAllContents===true)
          {
            setIsLoadedAllContents(false);
            setPageSizeOnContent(15);
          }
          
            // console.log("Data for images is ",JSON.stringify(response.data));
            setTotalNumberOfContentTemplates(response.data.toatalContentTemplates);
            setConentData(response.data.data);
          setCreaterContentLoader(false);
        } catch (error) {
            setCreaterContentLoader(false);
          // console.log(error);
        }
        setCreaterContentLoader(false);
      }
  
    
      const getAllCreatersContent=async()=>{

        setCreaterContentLoader(true);
        let pagesize=PageSizeOnContent;
        let responsesize=PageSizeOnContent;
        let currentpage=1;
        let createrscontent=[];
        while(responsesize===pagesize)
        {
          if(CreaterContentLoader===false)
          {
            setCreaterContentLoader(true);
          }
        try {
          const response = await API.get('Creaters-Content-Templates', '/creaters-content', {
            response: true,
            queryStringParameters: {
              page: currentpage,
              limit : pagesize,
        
            }
          });
          
            // console.log("Data for images is ",JSON.stringify(response.data));
            setTotalNumberOfContentTemplates(response.data.toatalContentTemplates);
            responsesize=response.data.data.length;
            createrscontent=createrscontent.concat(response.data.data);
        } catch (error) {
            console.log("Something went wrong while fetching content",error);
            break;
          
        }
        currentpage++;
      }
        setCreaterContentLoader(false);
        setPageSizeOnContent(createrscontent.length);
        setTotalNumberOfContentTemplates(createrscontent.length);
        setIsLoadedAllContents(true);
        setCurrentPageOfContent(1);
        setConentData(createrscontent);


      }
       // Get SInged Url For Content Tempalte File 
       const getSignedUrlForContentTemplateFile=async()=>{
        setCreaterContentLoader(true)
        try {
          const response = await API.get('Content-Template-file', '/content-template', {
            response: true,
          });
            // console.log("Data for images is ",JSON.stringify(response.data));
            setContentTempalteFileLink(response.data.data);
          setCreaterContentLoader(false);
        } catch (error) {
            setCreaterContentLoader(false);
          // console.log(error);
        }
      }

    const handleAddContent=async(contentname,contentDescription,contenttags,contentimage,contentimagename,contentdata,wordcount,category,audience)=>{
        // console.log("tempalte name is"+contentname+" and template tags are"+contenttags+"tempalte Type is"+templatetype);
        // console.log("\n Tempalte iamge is"+contentimage);
        // console.log("\n Template data is "+contentdata);
        const formattedDate = moment().format('YYYY-MM-DD');
        // console.log("\n User Name "+UserName);
        let sharedTemplate={
            ContentId:ConentData.length+1,
            ContentName:contentname,
            CreatedBy:UserName,
            ContentDescription:contentDescription,
            // ContentSource:ContentSource,
            CreatedDate:formattedDate,
            // isContentValidated:isvalidated,
            ContentCategory:category,
            ContentTargetAudience:audience,
            TagsList:contenttags,
            ContentImage:contentimage,
            ContentImageName:contentimagename,
            ContentData:contentdata,
            ContentRating:null,
            RatedUsersIDs:[],
            ConentUsedNumber:0,
            ContentWordsCount:wordcount,
            UserId:UserId
        };
        let tempaltedata=await handleStoreCreatedContent(sharedTemplate);
        let updateddata=sharedTemplate;
        if(tempaltedata!==false)
        {
          if(tempaltedata.ContentImage)
          {
            updateddata={...sharedTemplate,ContentId:tempaltedata.ContentId,ContentImage:tempaltedata.ContentImage}
          }
          else
          {
            updateddata={...sharedTemplate,ContentId:tempaltedata.ContentId}
          }
            
        }
        
        let communitycontent=ConentData;
        communitycontent.push(updateddata);
        setConentData(communitycontent);
        setCreaterContentLoader(false);
        return true;
    }

/*
    # handle Store Template in Db
*/
    const handleStoreCreatedContent=async(Tempaltedata)=>{
        setCreaterContentLoader(true);
        let data=[];
        data.push(Tempaltedata);
        let RequestBody={
          Data:data
        };
      
        try {
          const response = await API.post('Creaters-Content-Templates', '/creaters-content', {
            body:RequestBody,
            response: true,
          });
      //  console.log("operation Successfull"+response.data);
      setCreaterContentLoader(false);
          return response.data.data;
          //  setLocalStore(response.data.data);
        } catch (error) {
          console.log(error);
          setCreaterContentLoader(false);
          // setLocalStore([]);
           return false;
    }
}

/*
  #  handle Bulk Upload COntent 
*/
const handleBulkStoreCreatedContent=async(Tempaltedata)=>{
  setCreaterContentLoader(true);
  let RequestBody={
    Data:Tempaltedata,
    IsBulk:true
  };

  try {
    const response = await API.post('Creaters-Content-Templates', '/creaters-content', {
      body:RequestBody,
      response: true,
    });
//  console.log("operation Successfull"+response.data);
  setCreaterContentLoader(false);
    return response.data.data;
    //  setLocalStore(response.data.data);
  } catch (error) {
    console.log(error);
    setCreaterContentLoader(false);
    // setLocalStore([]);
    return [];
     
}
}
/*
# handle Update Tempalte USed  and Rating 
*/
const handleUpdateContentUsedAndRating=async(Tempaltedata)=>{
    setUpdateContentUseAndRatingLoader(true);
    let data=[];
    data.push(Tempaltedata);
    // console.log("Template is "+JSON.stringify(Tempaltedata))
    let RequestBody={
      Data:data,
      ContentId:Tempaltedata.ContentId,
    };
    try {
      const response = await API.put('Creaters-Content-Templates', '/creaters-content', {
        body:RequestBody,
        response: true,
      });
      setUpdateContentUseAndRatingLoader(false);
    return true;
      //  setLocalStore(response.data.data);
    } catch (error) {
      console.log(error);
      // setLocalStore([]);
      setUpdateContentUseAndRatingLoader(false);
       return false;
}
}

// handle Detlete Community Content Tempalte
const handleDeleteCreatersContentBackend=async(Tempaltedata)=>{

  let RequestBody={
    ContentId:Tempaltedata.ContentId
  };

  setUpdateContentUseAndRatingLoader(true);
  try {
    const response = await API.del('Creaters-Content-Templates', '/creaters-content', {
      body:RequestBody,
      response: true,
    });
    
    setUpdateContentUseAndRatingLoader(false);
    console.log("operation Successfull"+response.data);
    return true;
    //  setLocalStore(response.data.data);
    
  } catch (error) {
    console.log(error);
    // setLocalStore([]);
    setUpdateContentUseAndRatingLoader(false);
     return false;
  }
 }

    let ContextData={
        ConentData:ConentData,
        CreaterContentLoader:CreaterContentLoader,
        UpdateContentUseAndRatingLoader:UpdateContentUseAndRatingLoader,
        ContentTempalteFileLink:ContentTempalteFileLink,
        SecretKey:SecretKey,
        ConnectChatGPtLoader:ConnectChatGPtLoader,
        IsChatGptConnected:IsChatGptConnected,
        NumberOfPagesToDisplayOnPaginationOnContent:NumberOfPagesToDisplayOnPaginationOnContent,
        PageSizeOnContent:PageSizeOnContent,
        TotalNumberOfContentTemplates:TotalNumberOfContentTemplates,
        CurrentPageOfContent:CurrentPageOfContent,
        getAllCreatersContent:getAllCreatersContent,
        getCreatersContent:getCreatersContent,
        setCurrentPageOfContent:setCurrentPageOfContent,
        setUpdateContentUseAndRatingLoader:setUpdateContentUseAndRatingLoader,
        setConentData:setConentData,
        handleAddContent:handleAddContent,
        handleUpdateContentUsedAndRating:handleUpdateContentUsedAndRating,
        handleDeleteCreatersContentBackend:handleDeleteCreatersContentBackend,
        handleBulkStoreCreatedContent:handleBulkStoreCreatedContent,
        handleSecretKeyChange:handleSecretKeyChange,
        handleConnectChatGpt:handleConnectChatGpt,
        handleDiconnectChatGpt:handleDiconnectChatGpt,
    }
  return (
    <ContentContext.Provider value={ContextData}>
    {children}
   </ContentContext.Provider>
  )
  }
   