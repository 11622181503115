import React from 'react';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AuthContext from '../AppContext/AuthContext';
import AppHeader from '../Header/AppHeader';

const AppLayout = ({ children }) => {
  const {IsAuthenticated}=useContext(AuthContext);
    const Location=useLocation();
  return (
    <div>
       {
        Location.pathname!=="/" && Location.pathname!=="/forgotpassword" && IsAuthenticated===true?(
            <AppHeader />
        ):(null)
       } 
       
        <main>
        {children}
        </main>
    </div>
  )
}

export default AppLayout