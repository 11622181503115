import React, { useContext, useEffect,  } from 'react';
import AuthContext from '../AppContext/AuthContext';
import Loader from '../AppAssets/Loader.gif';
import { Amplify } from 'aws-amplify';
import awsConfig from '../aws-config';

var amplify=Amplify;
const Speak2Redirect = () => {
    const {checkUserAuthenticated,setIsSpeak2User}=useContext(AuthContext);
    useEffect(()=>{
        awsConfig.userPoolId=process.env.REACT_APP_SPEAK2_USER_POOL_ID;
        awsConfig.userPoolWebClientId=process.env.REACT_APP_SPEAK2_USER_POOL_CLIENT_ID;
        awsConfig.identityPoolId=process.env.REACT_APP_SPEAK2_IDENTITY_POOL_ID
        awsConfig.oauth.domain="speak2.auth.us-east-1.amazoncognito.com";
        awsConfig.oauth.scope=['email', 'openid', 'phone'];
        const Speak2Redirect=awsConfig.oauth.redirectSignIn.replace("/redirect", "/speak2-redirect")
        awsConfig.oauth.redirectSignIn=Speak2Redirect;
        amplify.configure(awsConfig);
        setIsSpeak2User(true);
        localStorage.setItem("wasSpeak2User",true);
        checkUserAuthenticated();
    },[])
  return (
    <div>
        <div className='absolute top-1/4  h-24 w-full text-center'>
            <span className='font-lato font-light  text-3xl' >Please Wait....Redirecting</span>
        </div>
        <div className='relative  h-screen'><img className='absolute top-1/2 left-1/2 h-24 2-34'  src={Loader} alt="Loading...please wait" /></div>
    </div>
  )
}

export default Speak2Redirect