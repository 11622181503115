import { API } from "aws-amplify";
import { createContext, useEffect, useState } from "react";


const ProfileContext=createContext();
export default ProfileContext;




export const ProfileContextProvider = ({children}) => {
   const [UserProfileLoader,setUserProfileLoader]=useState(false);
   const [UserProfileData,setUserProfileData]=useState(null);
   const [UserSocialIconsData,setUserSocialIconsData]=useState(null);
   const [ShouldUpdate,setShouldUpdate]=useState(false);
   const [UserContributionStats,setUserContributionStats]=useState({
    SharedTemplates:0,
    CommunityContentTemplates:0,
    CreatorsContents:0
   });

   useEffect(()=>{
    handleGetUserProfile(); 
   
   },[]);

    // handle Get User Profile data
    const handleGetUserProfile=async()=>{
      setUserProfileLoader(true);
        try {
            const response = await API.get('profile', '/profile', {
              response: true,
            });
            
            //  console.log("Data for images is ",JSON.stringify(response.data));
             setUserContributionStats(response.data.ContribStats);
             if(response.data.ProfileData)
             {
              const { SocialLinks, ...profileData } = response.data.ProfileData;
            //  console.log("SOcial Icons are",SocialLinks);
             setUserProfileData(profileData);
             setUserSocialIconsData(SocialLinks);
             }
             
             setUserProfileLoader(false); 
          } catch (error) {
            console.log(error);
            setUserProfileData(null);
            setUserSocialIconsData(null);
            setUserProfileLoader(false); 
            
          }
    }

    // handle add User Profile data 
    const handleAddUserProfileData=async(RequestBody)=>{
      setUserProfileLoader(true);
        try {
            const response = await API.post('profile', '/profile', {
            body:RequestBody,
            response: true,
            });
            
            //  console.log("Data for Profile  is ",JSON.stringify(response.data));
             setUserProfileLoader(false);
             return response.data;
            
          } catch (error) {
            console.log(error);
            setUserProfileLoader(false);
            return null;
            
          }
    }

    /*
  # Upload File To S3 
*/
const uploadImageToS3=async(signedUrl,file,contentType)=>{

  setUserProfileLoader(true);
  try {
      const response = await fetch(signedUrl, {
          method: 'PUT',
          headers: {
              'Content-Type': contentType,
              'Content-Disposition':'attachment' // Ensure this matches the Content-Type in the signed URL
          },
          body: file // Directly use the File object from the input
      });

      if (response.ok) {
          console.log('Upload successful');
          setUserProfileLoader(false);
          return true;
      } else {
          console.error('Upload failed');
          setUserProfileLoader(false);
          return false;
      }
  } catch (error) {
      console.error('Error uploading the file:', error);
  }
}

   


    const  getImageFromSIgnedUrl=async(signedUrl, filename)=>{
      setUserProfileLoader(true);
      try {
          // Fetch the file content from the signed URL
          const response = await fetch(signedUrl);
          if (!response.ok) {
            console.log("Something went wrong while fethcing file")
            return null;
          }
          
          
          // Convert the response into a Blob
          const blob = await response.blob();
          
          // Create a file object from the Blob
          const file = new File([blob], filename, { type: blob.type });
          setUserProfileLoader(false);
          return file;
      } catch (error) {
          console.error('Failed to fetch the file:', error);
          setUserProfileLoader(false);
          return null;
      }
    }
    // convert file to base 64
    function convertImageToBase64(file) {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
          reader.readAsDataURL(file);
      });
    }
    let ContextData={
        UserProfileLoader:UserProfileLoader,
        UserContributionStats:UserContributionStats,
        UserProfileData:UserProfileData,
        UserSocialIconsData:UserSocialIconsData,
        ShouldUpdate:ShouldUpdate,
        setShouldUpdate:setShouldUpdate,
        setUserProfileData:setUserProfileData,
        setUserSocialIconsData:setUserSocialIconsData,
        handleAddUserProfileData:handleAddUserProfileData,
        uploadImageToS3:uploadImageToS3,
        convertImageToBase64:convertImageToBase64,
        getImageFromSIgnedUrl:getImageFromSIgnedUrl,
        
    }
  return (
    <ProfileContext.Provider value={ContextData}>
    {children}
   </ProfileContext.Provider>
  )
  }
   