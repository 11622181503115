

import Footer from "../AppComponents/Footer/Footer";
import Header from "../AppComponents/Header/Header";
import LoginAndSignup from '../AppComponents/LoginAndSignup';



const LandingPage = () => {
  return (
    <div className='flex flex-col space-y-4 items-center'> 
     
        <Header/>

        {/* <div>
          <span className='text-xl font-semibold text-[#9D2021]'> Connect your FedEx account for easy print ordering!</span>
        </div> */}

        <div className='w-[60%]'>
        <LoginAndSignup/>
        </div>

       
        <Footer/>

     </div>
     
  )
}

export default LandingPage