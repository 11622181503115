import { useContext,useState} from 'react';
import { NavLink,useLocation} from 'react-router-dom';
import Logo from '../AppAssets/Logo.png';
import AuthContext from '../AppContext/AuthContext';
import {BsChevronDown} from 'react-icons/bs'


const AppHeader = () => {
  const {handleLogOut,UserName} =useContext(AuthContext);
  const Location=useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const logOutHandler=()=>{
    setShowDropdown(!showDropdown);
    handleLogOut()
  }


  return (
    <div className="flex px-10 items-end pt-2 w-full justify-start font-lato " style={{backgroundColor:"#f1f1f1"}}  >
            <div className='w-[350px]'>
                <img src={Logo} className="w-[170px] h-[50px]" alt='Print Center' />
                {/* <span className='text-xs '>Drag and Drop Newsletter & Calendar Creation</span> */}
            </div>
           
            {/* <div 
          className={
            `text-black   w-[75%] mx-12  rounded  bg-[#1F3566] ${ShowMessage===true?'visible':'invisible'}  `
          }
        >
          
          <span className={`inline-block align-middle pt-2 pb-2  ml-5 ${SuccessMessage===true?'text-[#FFFFFF]':'text-red-600'}  font-bold`}>
         {Message}
          </span>
          <button
            className={`float-right mr-2 pt-2 pb-2 align-middle text-3xl font-bold rounded-full ${SuccessMessage===true?'text-[#FFFFFF]':'text-red-600'}`}
           onClick={()=>setShowMessage(false)}
          >
            <span>×</span>
          </button>
        </div> */}
        <div className='rounded-lg px-4 font-lato font-light  h-12 items-center flex justify-between  space-x-4 mx-2 w-full' style={{backgroundColor:"#ffff",color:"#404040",border:"solid 1px #979797"}}>
          <div className='space-x-4'>
          <div className="relative inline-block group ">
          <NavLink className={`h-full py-2 px-2 font-lato font-light hover:bg-[#E8E8E8] ${Location.pathname==="/shares"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `} to="/shares" >
            Shares
            <BsChevronDown className="inline-block ml-1"/>
           </NavLink>
        <div className="absolute hidden group-hover:flex flex-col space-y-2  z-[1000] mt-1 py-3 px-2 bg-white shadow-md rounded-md ">
        <NavLink className={`h-full py-2 px-2 font-lato font-light hover:bg-[#E8E8E8] whitespace-nowrap ${Location.pathname==="/upload-share"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `} to="/upload-share" >Upload and Share</NavLink>
        <NavLink className={`h-full py-2 px-2 font-lato font-light hover:bg-[#E8E8E8] whitespace-nowrap ${Location.pathname==="/create-share"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `} to="/create-share" >Create and Share</NavLink>
          {/* Add more dropdown links here if needed */}
        </div>
      </div>
          <NavLink  className={`h-full py-2 px-2 font-lato font-light hover:bg-[#E8E8E8] ${Location.pathname==="/newsletterapp"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `} to="/newsletterapp"   >Newsletter </NavLink>
          <NavLink className={`h-full py-2 px-2 font-lato font-light hover:bg-[#E8E8E8] ${Location.pathname==="/calendarapp"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `}  to="/calendarapp" >Calendar </NavLink>
          <NavLink className={`h-full py-2 px-2 font-lato font-light hover:bg-[#E8E8E8] ${Location.pathname==="/slideapp"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `}  to="/slideapp" >Slides </NavLink>
          <NavLink className={`h-full py-2 px-2 font-lato font-light hover:bg-[#E8E8E8] ${Location.pathname==="/designs"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `} to="/designs" >Designs</NavLink>
          {/* <NavLink className={`h-full py-2 px-2 font-lato font-light hover:bg-[#E8E8E8] ${Location.pathname==="/content"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `} to="/content" >Content </NavLink>
          <NavLink className={`h-full py-2 px-2 font-lato font-light hover:bg-[#E8E8E8] ${Location.pathname==="/content-creater"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `} to="/content-creater" >Content Creator</NavLink> */}
          
          <div className="relative inline-block group ">
          <NavLink className={`h-full py-2 px-2 font-lato font-light hover:bg-[#E8E8E8] ${Location.pathname==="/content"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `} to="/content" >
            Content
            <BsChevronDown className="inline-block ml-1"/>
           </NavLink>
        <div className="absolute hidden group-hover:block z-[1000] mt-1 py-3 px-2 bg-white shadow-md rounded-md ">
        <NavLink className={`h-full py-2 px-2 font-lato font-light hover:bg-[#E8E8E8] whitespace-nowrap ${Location.pathname==="/content-creater"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `} to="/content-creater" >Content Creator</NavLink>
          {/* Add more dropdown links here if needed */}
        </div>
      </div>
      <NavLink className={`h-full py-2 px-2 font-lato font-light hover:bg-[#E8E8E8] ${Location.pathname==="/help"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `} to="/help" >Help </NavLink>
          </div>

          <div className='mx-2 '>
          <div className="relative item-center"  
                    >
                  <button
                   onClick={handleDropdownToggle}
                    className="text-gray-400 inline-flex items-center space-x-2    rounded-md text-sm font-medium"
                  >
                     
                    {/* <span>My Account</span> */}
                    <span className='font-lato font-light'>{UserName}</span>
                    <BsChevronDown className="block ml-1"/>
                   
                  </button>
                  {showDropdown && (
                    <div className="absolute right-0 mt-2 w-48 bg-gray-200 rounded-md shadow-lg z-[300]">
                      <div className="py-1">
                        
                            <NavLink
                            onClick={handleDropdownToggle}
                              to="/profile"
                              className="block px-4 py-2 text-sm text-gray-500  hover:bg-white hover:text-gray-500"
                            >
                              Profile
                            </NavLink>
                        
                        
                      
                        <button
                          onClick={logOutHandler}
                          className="block px-4 py-2 text-sm text-gray-500 hover:bg-white  hover:text-gray-500 w-full text-left"
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
          </div>
        </div>
            {/* <div className='inline-flex space-x-8 '>

              <div className='inline-flex border border-blue-500 hover:border-transparent rounded '>
              <button onClick={handleDownloadImage} disabled={ExportLoader===true?true:false} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 rounded">
                  
                  {
                    ExportLoader===true?"Processing":"Export"
                  }
               </button>
               <select onChange={handleChangeFormat}  className="bg-transparent text-blue-700 font-semibold hover:text-white hover:bg-blue-500 rounded  ">
       
            {
            ExportFormats.map((item,index)=>(
              <option key={index}  value={item.format}> {item.format} </option>
            ))
            }
       
       
         </select>
              </div>


              <button onClick={CurrentSavedItem===-1?()=>setSaveTemplateFormToggle(true):SaveCurrentNewsLetter} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                  Save
               </button>
               
               <ReactToPrint
            
        trigger={()=>{
            return ( <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
            Print
         </button>)
        }}
        content={()=>PrintRef.current}
        
        documentTitle='Print Center'
        />
             
              
            </div> */}
           
          
    </div>
  )
}

export default AppHeader