export const CategoryItems = [
    { CategoryName: 'Activity' },
    { CategoryName: 'Newsletter' },
    { CategoryName: 'Daily' },
    { CategoryName: 'Menu' },
    { CategoryName: 'Signage' },
  ];

export const SuggestedTags = [
    { TagName: 'Activity', value: 'Activity', label: 'Activity' },
    { TagName: 'Affirmation', value: 'Affirmation', label: 'Affirmation' },
    { TagName: 'Newsletter', value: 'Newsletter', label: 'Newsletter' },
    { TagName: 'Flyer', value: 'Flyer', label: 'Flyer' },
    { TagName: 'Announcement', value: 'Announcement', label: 'Announcement' },
    { TagName: 'Seasons', value: 'Seasons', label: 'Seasons' },
    { TagName: 'Mentorship', value: 'Mentorship', label: 'Mentorship' },
    { TagName: 'Religious', value: 'Religious', label: 'Religious' },
    { TagName: 'Holiday', value: 'Holiday', label: 'Holiday' },
    { TagName: 'Puzzles', value: 'Puzzles', label: 'Puzzles' },
    { TagName: 'Quiz', value: 'Quiz', label: 'Quiz' },
    { TagName: 'Recipe', value: 'Recipe', label: 'Recipe' },
    { TagName: 'Sports', value: 'Sports', label: 'Sports' },
    { TagName: 'Movie', value: 'Movie', label: 'Movie' },
    { TagName: 'Trivia', value: 'Trivia', label: 'Trivia' },
    { TagName: "What's the Difference?", value: "What's the Difference?", label: "What's the Difference?" },
    { TagName: 'Books', value: 'Books', label: 'Books' },
    { TagName: 'Music', value: 'Music', label: 'Music' },
    { TagName: 'Songs', value: 'Songs', label: 'Songs' },
    { TagName: 'Short Stories', value: 'Short Stories', label: 'Short Stories' },
    { TagName: 'Plays', value: 'Plays', label: 'Plays' },
    { TagName: 'Poems', value: 'Poems', label: 'Poems' },
    { TagName: 'Top 5', value: 'Top 5', label: 'Top 5' },
    { TagName: 'Top 10', value: 'Top 10', label: 'Top 10' },
    { TagName: 'Pros and Cons', value: 'Pros and Cons', label: 'Pros and Cons' },
    { TagName: 'Wellness Tips', value: 'Wellness Tips', label: 'Wellness Tips' },
    { TagName: 'Quotes', value: 'Quotes', label: 'Quotes' },
    { TagName: 'Practice Interview', value: 'Practice Interview', label: 'Practice Interview' },
    { TagName: 'Crafts', value: 'Crafts', label: 'Crafts' },
    { TagName: 'Scripture', value: 'Scripture', label: 'Scripture' },
    { TagName: 'Jokes', value: 'Jokes', label: 'Jokes' },
    { TagName: 'Humor', value: 'Humor', label: 'Humor' },
    { TagName: 'Heartwarming', value: 'Heartwarming', label: 'Heartwarming' },
    { TagName: 'Other', value: 'Other', label: 'Other' },
];

