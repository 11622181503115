import { Auth } from "aws-amplify";

const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
const protocol = isLocalhost ? 'http://' : 'https://';
const hostname = window.location.hostname;
const port = isLocalhost ? ':3000' : '';

const awsConfig = {
  
  //  us-east-1:66a2b483-d23f-4b59-bac1-cdf2dd3fcc66
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_PRNTYR_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_PRNTYR_USER_POOL_CLIENT_ID,
  oauth: {
    domain: "prntyrapp.auth.us-east-1.amazoncognito.com",
    redirectSignIn: `${protocol}${hostname}${port}/redirect`,
    redirectSignOut: `${protocol}${hostname}${port}`,
    responseType: 'code'
  },

  API: {
    endpoints: [
      {
        name: 'Newsletter-bgndbd-images',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'slideapp-bgndbd-images',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'calendar-bgndbd-images',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'calendarapp-premade-templates',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'slideapp-premade-templates',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'calendarapp-custom-bgnbd-images',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'newsletterapp-custom-bgnbd-images',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'slideapp-custom-bgnbd-images',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },

      {
        name: 'Calendarapp-user-templates',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'Newsletterapp-user-templates',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },

      {
        name: 'Slideapp-user-templates',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'Community-Content-Templates',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'SlideApp-signage-data',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'PDF-data',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },

      {
        name: 'Creaters-Content-Templates',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'Content-Template-file',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'ChatGpt-Content-Generation',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'Connect-ChatGpt',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'ChatGpt-Template',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'Creators-templates',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: 'profile',
        endpoint: process.env.REACT_APP_BACKEND_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`
          }

        }
      },
      {
        name: "lambda",
        endpoint: "https://atqxeqncag.execute-api.us-east-1.amazonaws.com/alexa-speak2-live",
        region: 'us-east-1',
      }
    ]
  }
};
export default awsConfig;