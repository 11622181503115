import React from 'react'
import { useContext } from 'react';
import { Navigate, } from 'react-router-dom'
import AuthContext from '../AppContext/AuthContext';

const PrivateRoute = ({ children }) => {
    const {IsAuthenticated}=useContext(AuthContext);

 
    if (!IsAuthenticated) {
      return <Navigate to={`/`} />;
    }
  
    return (
        <>
       {children}
       </>
      );
}

export default PrivateRoute