import React, { useContext, useEffect,  } from 'react';
import AuthContext from '../AppContext/AuthContext';
import Loader from '../AppAssets/Loader.gif';

const Redirect = () => {
    const {checkUserAuthenticated}=useContext(AuthContext);
    useEffect(()=>{
        checkUserAuthenticated();
    },[])
  return (
    <div>
        <div className='absolute top-1/4  h-24 w-full text-center'>
            <span className='font-lato font-light  text-3xl' >Please Wait....Redirecting</span>
        </div>
        <div className='relative  h-screen'><img className='absolute top-1/2 left-1/2 h-24 2-34'  src={Loader} alt="Loading...please wait" /></div>
    </div>
  )
}

export default Redirect